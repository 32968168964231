<template lang="pug">
    .main-wrapper.agente-listar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-12.ta-right
                    h1.text-header.text-secondary MedClub / <b>Agentes</b>

        Panel.mb-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
            .p-grid.p-fluid.p-align-end

                .p-col-12.p-md-3
                    label.form-label CPF:
                    .p-inputgroup
                        InputMask(
                            v-model='filters.nr_cpf'
                            mask='999.999.999-99'
                            placeholder='CPF'
                            :autoClear='false'
                            @input='(val) => !!val || waiting || applyFilters()'
                            @keydown.enter='applyFilters()'
                        )
                        Button(icon='jam jam-search' @click='applyFilters()')

                .p-col-12.p-md-3
                    label.form-label Nome:
                    .p-inputgroup
                        InputText(
                            placeholder='Nome'
                            :autoClear='false'
                            @keyup.enter.native='applyFilters()'
                            @input='(val) => !!val || applyFilters()'
                            v-model='filters.nm_pessoa_fisica'
                        )
                        Button(icon='jam jam-search' @click='applyFilters()')

                .p-col-12.p-md-3
                    label.form-label Código do agente:
                    .p-inputgroup
                        InputText(
                            placeholder='Código do agente'
                            :autoClear='false'
                            @keyup.enter.native='applyFilters()'
                            @input='(val) => !!val || applyFilters()'
                            v-model='filters.ds_codigo'
                        )
                        Button(icon='jam jam-search' @click='applyFilters()')
                .p-col-12.p-md-3
                    label.form-label Status:
                    Dropdown(
                        v-model='filters.ie_agente_ativo'
                        :options='options.ieStatus'
                        optionLabel='text'
                        optionValue='value'
                        dataKey='value'
                        placeholder='TODOS'
                        @change='applyFilters()'
                    )

        .ta-right.my-2
            Button(label='Adicionar' icon="jam jam-plus" @click="$router.push('/agente-medclub/salvar/0/?agente=true')")

        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else-if='! list.length')
            p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
        div(v-else)
            DataView.dataview.my-2(:value="list" layout="grid")
                template(#grid="props")
                    .p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
                        Panel.panel-list.ta-center(:header="props.data.nm_pessoa_fisica" style='position: relative')
                            .ta-left
                                p <b>CPF:</b> {{ props.data.nr_cpf_f }}
                                p <b>Código:</b> {{ props.data.ds_codigo }}
                                p <b>Data de nascimento:</b> {{ props.data.dt_nascimento_f }}
                                p <b>Sexo:</b> {{ props.data.ie_sexo_f }}
                                p <b>Telefone:</b> {{ props.data.nr_telefone_f }}
                                    a.whatsapp-link(v-if='props.data.nr_telefone' :href="`https://api.whatsapp.com/send?phone=${ props.data.nr_telefone_ws }`" target='_blank')
                                        i(class='jam jam-whatsapp')
                            .ta-right.p-buttonset.mt-3
                                Button.mr-1.p-button-raised.p-button-rounded(icon="jam jam-write"
                                    @click="$router.push(`/agente-medclub/salvar/${ props.data.id }/?agente=true`)")

            Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

            Panel.datatable(header='Lista de agentes')
                Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
                DataTable(:value="list" @sort="onSort($event)" :sortField="order.field" :sortOrder="order.sortOrder")
                    Column(headerStyle='width: 5%;' bodyStyle='text-align: center;')
                        template(#body='props')
                            ProgressSpinner.small-spinner(v-if='waitingStatusId === props.data.id' strokeWidth='6')
                            .status-indicator(v-else :class="{ off: ! props.data.ie_agente_ativo }" @click='alterarStatus(props.data)')
                                i.jam(:class="{ 'jam-check': props.data.ie_agente_ativo, 'jam-minus': !props.data.ie_agente_ativo }")
                    Column(headerStyle='width: 25%;' field='nm_pessoa_fisica' header='Nome' sortable)
                    Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='nr_cpf_f' header='CPF' sortable)
                    Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='ds_codigo' header='Cód. Agente')
                    Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='dt_nascimento_f' header='Nascimento' sortable)
                    Column(headerStyle='width: 8%;' bodyStyle='text-align: center;' field='ie_sexo_f' header='Sexo')
                    Column(headerStyle='width: 14%;' bodyStyle='text-align: center;' header='Telefone')
                        template(#body='props')
                            span {{ props.data.nr_telefone_f }}
                            a.whatsapp-link(v-if='props.data.nr_telefone' :href="`https://api.whatsapp.com/send?phone=${ props.data.nr_telefone_ws }`" target='_blank')
                                i(class='jam jam-whatsapp')
                    Column(headerStyle='width: 8%; text-align: center' header="Ações")
                        template(#body='props')
                            .ta-center
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    v-tooltip.top="'Editar'"
                                    icon="jam jam-write"
                                    @click="$router.push(`/agente-medclub/salvar/${ props.data.id }/?agente=true`)"
                                )
                Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>

<style lang="scss">
.agente-listar {
    .dataview {
        @media all and (min-width: 577px) {
            display: none;
        }
    }
    .datatable {
        @media all and (max-width: 576px) {
            display: none;
        }
        .cell {
            padding: 16px 0;
            text-align: center;
            &.ex  {  background-color: #e4f8e1; }
            &.a  { background-color: #faf3dd; }
        }
    }
    .whatsapp-link {
        margin-left: 8px;
        background-color: #0da64d;
        color: #fff;
        font-weight: 700;
        border-radius: 50%;
        padding: 4px;
        display: inline-block;
        vertical-align: middle;
        width: 28px;
        height: 28px;
        line-height: 24px;
        text-align: center;
        transition: 0.25s;
        &:hover {
            background-color: #0a7d3a;
        }
    }
    .status-indicator {
        display: inline-block;
        font-size: 0;
        width: 23px;
        height: 23px;
        line-height: 36px;
        border-radius: 50%;
        background-color: #94c860;
        border: 1px solid #84b553;
        transition: 250ms;
        cursor: pointer;
        padding-right:10px;
        &:hover { background-color: #84b553; }
        &.off {
            background-color: #d33838;
            border: 1px solid #b33030;
            &:hover { background-color: #b33030; }
        }
        .jam {
            color: #fff;
            margin-left:2px;
        }
    }
    .small-spinner {
        width: 25px;
        height: auto;
    }
}
</style>

<script>
import ProgressBar from 'primevue/progressbar'
import DataView from 'primevue/dataview'
import Panel from 'primevue/panel'
import Paginator from 'primevue/paginator'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import InputMask from 'primevue/inputmask'
import Button from 'primevue/button'
import Tooltip from 'primevue/tooltip'
import ProgressSpinner from 'primevue/progressspinner'
import Dropdown from 'primevue/dropdown'

import { Paciente } from './../../middleware'
import moment from 'moment'
import wsConfigs from './../../middleware/configs'
import { fixTelefoneFormat } from './../../utils'

export default {
    created () { this.applyFilters() },
    components: { ProgressBar, DataView, Panel, Paginator, DataTable, InputMask,
        Column, Button, Tooltip, ProgressSpinner, InputText, Dropdown},
    directives: { tooltip: Tooltip },
    data () {
        return {
            list: [],
            windowInnerWidth: window.innerWidth,
            waiting: false,
            waitingStatusId: 0,
            filters: {
                nr_cpf: '',
                nm_pessoa_fisica: '',
                indicador: true,
                ie_agente_ativo: 'true'
            },
            options: {
                ieStatus:[
                    { text: "TODOS", value: null },
                    { text: "Ativo", value: 'true' },
                    { text: "Inativo", value: 'false' },
                ]
            },
            paginator: {
                page: this.$route.query.pg ? this.$route.query.pg : 1,
                per_page: wsConfigs.paginator_perPage,
                count: 0
            },
            order: {
                field: 'nm_pessoa_fisica',
                sortOrder: 1
            },
        }
    },
    methods: {
        getList (params) {
            this.waiting = true
            Paciente.findAll(params).then(response => {
                if (response.status === 200) {
                    this.paginator.count = response.data.count
                    response.data.results.forEach(paciente => {
                        paciente.dt_nascimento_f = moment(paciente.dt_nascimento).format('DD/MM/YYYY')
                        paciente.ie_sexo_f = ({ M: 'Masculino', F: 'Feminino' })[paciente.ie_sexo]
                        if (paciente.nr_telefone) paciente.nr_telefone_f = fixTelefoneFormat(paciente.nr_telefone)

                        paciente.nr_telefone_ws = paciente.nr_telefone.match(/\d+/g).join('')
                        if (paciente.nr_telefone_ws.substr(0, 2) === '55') {
                            paciente.nr_telefone_ws = `${ paciente.nr_telefone_ws }`
                        } else {
                            paciente.nr_telefone_ws = `55${ paciente.nr_telefone_ws }`
                        }

                        if (paciente.nr_cpf) paciente.nr_cpf_f = paciente.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                        if (paciente.ds_email) {
                            let strMaxLength = 24
                            paciente.ds_email_f = paciente.ds_email.length > strMaxLength ?
                                `${ paciente.ds_email.substr(0, strMaxLength) }...` : paciente.ds_email
                        }
                    })
                    this.list = response.data.results
                } else {
                    this.list = []
                }
                this.waiting = false
            })
        },
        applyFilters (page) {
            this.paginator.page = page || 1
            let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }

            params.order = `${ this.order.sortOrder == -1 ? '-' : '' }${ this.order.field }`

            if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
            Object.keys(this.filters).forEach((key) => {
                if (key == 'nr_cpf') params[key] = this.filters[key].replace(/(\.|-|_)/g, '')
                else if (key.substr(0, 3) == 'dt_') {
                    if (moment(this.filters[key], 'DD/MM/YYYY').isValid())
                        params[key] = moment(this.filters[key], 'DD/MM/YYYY').format('YYYY-MM-DD')
                } else if (this.filters[key]) params[key] = this.filters[key]
            })
            this.getList(params)
        },
        alterarStatus(dados) {
            let dataSend = {id: dados.id, ie_agente_ativo: !dados.ie_agente_ativo}

            this.waitingStatusId = dados.id
            Paciente.save(dataSend).then(response => {
                this.waitingStatusId = 0
                if ([200, 201].includes(response.status)) {
                    dados.ie_agente_ativo = dataSend.ie_agente_ativo
                } else if (response.data.detail) {
                    if (typeof response.data.detail == 'string')
                        this.$toast.error(response.data.detail, { duration: 3000 })
                    else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                }
            })
        },
        onPage (ev) {
            this.applyFilters(ev.page + 1)
        },
        onSort(ev) {
            this.order.field = ev.sortField
            this.order.sortOrder = ev.sortOrder
            this.applyFilters()
        },
    }
}
</script>
